<template>
  <div>
    <v-card-title class="display-1 mb-2">
      {{t('$vuetify.serviceCenter.requestCharger')}}
      <v-spacer/>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            large
          >
            mdi-progress-question
          </v-icon>
        </template>
        <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.<br>
            Magnam, neque totam quasi ipsum necessitatibus amet <br>
            excepturi ex corporis, aut impedit in ratione animi<br>
            fugit dolorem voluptatum vel recusandae eaque molestias!
        </span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>{{t('$vuetify.serviceCenter.typeCharger')}}</v-card-subtitle>
    <v-card-text>
      <v-radio-group v-model="radioGroupGuestCharging">
        <v-row>
          <v-col cols="12"  lg="6">
            <v-card flat outlined min-height="300px">
              <v-card-text>
                <v-radio :label="t('$vuetify.serviceCenter.smartCable')"></v-radio>
              </v-card-text>
              <v-card-text align="center">
                  <v-img
                      width="20%"
                      lazy-src="/requests/smart_cables.svg"
                      src="/requests/smart_cables.svg"
                      class="mb-2"
                  ></v-img> <br>
                  <!-- 520 € -->
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12"  lg="6">
            <v-card flat outlined min-height="300px">
              <v-card-text>
                <v-radio
                  :label="t('$vuetify.serviceCenter.fixedCharger')"
                ></v-radio>
              </v-card-text>
              <v-card-text align="center">
                <v-img
                  v-if="isIncludeCable"
                  width="35%"
                  lazy-src="/requests/chargepoints_smart_cable.png"
                  src="/requests/chargepoints_smart_cable.png"
                  class="mb-2"
                ></v-img>
                <v-img
                  v-else
                  width="35%"
                  lazy-src="/requests/chargepoints.png"
                  src="/requests/chargepoints.png"
                  class="mb-2"
                ></v-img>
                <br>
                <!-- 720 € -->
                <v-switch
                  v-if="false"
                  v-model="isIncludeCable"
                  label="Include cable"
                ></v-switch>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <!-- Total Price : {{totPrice}} € -->
      <v-spacer />
      <v-btn
        color="primary"
        text
        @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
          :disabled="radioGroupGuestCharging === null"
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      radioGroupGuestCharging: 1,
      isIncludeCable: false
    }
  },
  mounted () {
    this.setPrice(this.radioGroupGuestCharging)
  },
  methods: {
    nextStep () {
      this.setPrice(this.radioGroupGuestCharging)
      this.$store.commit('requestsState/setSummary', 'Home Address - request')
      if (this.radioGroupGuestCharging !== 1) {
        this.$store.commit('requestsState/setChargerType', '13839')
        this.$store.commit('requestsState/setStep', 'SubscriptionType')
      } else {
        this.$store.commit('requestsState/setChargerType', '13851')
        this.$store.commit('requestsState/setStep', 'MountingType')
      }
    },

    previousStep () {
      if (this.userDT.data.Relation.IsCompany === 1) {
        this.$store.commit('requestsState/setChargerType', '')
        this.$store.commit('requestsState/setPreviousStepName')
      } else {
        this.$emit('back')
      }
    },

    cancel () {
      this.$emit('cancel')
    },

    setPrice (val) {
      const elm = { price: 720, type: 'chargertype' }
      if (val) {
        this.$store.commit('requestsState/setPrice', elm)
      } else {
        elm.price = 520
        this.$store.commit('requestsState/setPrice', elm)
      }
    }
  },

  computed: {
    ...mapGetters({ price: 'requestsState/price', totPrice: 'requestsState/totalPrice', userDT: 'user/userInfo' })
  },

  watch: {
    radioGroupGuestCharging: function (val) {
      this.setPrice(val)
    }
  }
}
</script>
